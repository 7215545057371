import { Helmet } from "react-helmet";
import "./App.css";
import LandingPage from "./LandingPage";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";
import ReactGA4 from "react-ga4";

const gaTrackingId = process.env.REACT_APP_GA_TRACKING_ID;
console.log(gaTrackingId);
ReactGA4.initialize(gaTrackingId, { debug: false });
ReactGA4.send({ hitType: "pageview", page: window.location.pathname });

ChannelService.loadScript();
ChannelService.boot({
  pluginKey: "9a411bb6-2dfb-4dfc-bc95-169a88fff1eb",
});

function App() {
  return (
    <div className="mynail_main_container">
      <Helmet>
        <title>마이네일</title>
        <meta
          name="description"
          content="누구보다 셀프네일아트를 사랑하는 당신을 위한 맞춤형 네일아트 재료 대여 서비스"
        />
        <meta property="og:title" content="내 손 안의 네일샵, 마이네일" />
        <meta property="og:url" content="https://mynail.kr/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/mynail_og_image.png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="500" />
        <meta property="og:site_name" content="Mynail" />
        <meta property="og:locale" content="ko_KR" />
        <meta
          property="og:description"
          content="누구보다 셀프네일아트를 사랑하는 당신을 위한 맞춤형 네일아트 재료 대여 서비스"
        />
        <link rel="icon" href="/favicon.ico" type="image/x-icon" />
      </Helmet>
      <LandingPage />
    </div>
  );
}

export default App;
